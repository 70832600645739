import React from "react";
import { Helmet } from "react-helmet";

const ScopedTheme = ({ children, scope = "tailwind" }) => {
    return (
        <>
            <Helmet
                bodyAttributes={{
                    class: scope
                }}
            />
            {children}
        </>
    );
};

export default ScopedTheme;
