import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

import get from "lodash/get";
import { privatePathPrefix } from "../../constants/privatePathPrefix";

const OrderNav = ({ orderNav }) => {
    const { nextSlug, prevSlug, order } = orderNav;
    return (
        <span className="mr-2">
            <li>
                {prevSlug !== null ? (
                    <Link to={`${privatePathPrefix}/${prevSlug}`}> ◀ </Link>
                ) : (
                    <span className="text-gray-400"> ◀ </span>
                )}
                <span className="font-bold">{order}</span>
                {nextSlug !== null ? (
                    <Link to={`${privatePathPrefix}/${nextSlug}`}> ▶ </Link>
                ) : (
                    <span className="text-gray-400"> ▶ </span>
                )}
            </li>
        </span>
    );
};

OrderNav.propTypes = {
    orderNav: PropTypes.object.isRequired
};

const BreadCrumbs = ({ crumbs, orderNav = {} }) => {
    const order = get(orderNav, "order", null);
    const ohCrumbs = [{ to: privatePathPrefix, title: "Chetwood Comms Library" }, ...crumbs];

    return (
        <nav className="bg-gray-200 py-2 px-3 font-sans w-full ">
            <ol className="list-reset flex text-gray-600 text-xs">
                {ohCrumbs.map((crumb) => {
                    if (crumb.current || ohCrumbs.length === 1) {
                        return (
                            <>
                                {order && <OrderNav orderNav={orderNav} />}
                                <li key={crumb.title}>{crumb.title}</li>
                            </>
                        );
                    }

                    return (
                        <Fragment key={crumb.title}>
                            <li>
                                <Link to={crumb.to} className="text-blue font-bold ">
                                    {crumb.title}
                                </Link>
                            </li>
                            <li>
                                <span className="mx-2">/</span>
                            </li>
                        </Fragment>
                    );
                })}
            </ol>
        </nav>
    );
};

BreadCrumbs.propTypes = {
    crumbs: PropTypes.arrayOf(
        PropTypes.shape({
            to: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            current: PropTypes.bool
        })
    ).isRequired,
    orderNav: PropTypes.shape({
        nextSlug: PropTypes.string,
        prevSlug: PropTypes.string,
        order: PropTypes.number
    })
};

BreadCrumbs.defaultProps = {
    orderNav: null
};

export default BreadCrumbs;
